import React from 'react';
import Graph from './components/Graph';
import NodeForm from './components/NodeForm';
import RelationshipForm from './components/RelationshipForm';
import logo from './0001.gif'; // Adjust the path if necessary
import 'antd/dist/reset.css'; // Looks after V5
import './index.css';
import { ConfigProvider, theme } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{algorithm:theme.darkAlgorithm}}
      >
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Sardines Editor</h1>
          <div>
            <h1>________________________________________</h1>
          </div>
        </div>
      </header>
      <Graph />
      {/* <NodeForm /> */}
      {/* <RelationshipForm /> */}
    </div>
    </ConfigProvider>
  );
}

export default App;